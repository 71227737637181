import React, { useState } from "react"

import LayoutOB from '../../components/layoutob.js'
import { useLocation } from '@reach/router'

import FormSignup from "../../components/Form/signup/FormSignup"
import { deDE } from "../../lang/de-DE"
import { deCH } from "../../lang/de-CH"
/**
 * Description of Checkout Functional component
 * @module Checkout
 * @returns {Object} Returns the form for users that were redirected from the app and already have an account, wrapped by the OB OnBoarding Layout which contains firebase and authentication.
 */
export default function CheckoutPage() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var usermailParam = searchParams.get("email");
    var countryParam = searchParams.get("country");
    const [country, setCountry] = useState(countryParam)
    let txt, language

    if (countryParam==="DE"){
        txt         =   deDE.Checkout
        language    =   "de-DE"
    }else{
        txt         =    deCH.Checkout
        language    =   "de-CH"
    }

    const handleUpdateCountry = input => {
        setCountry(input)
      }


    return (
        <LayoutOB location={countryParam} language={language}>
            <FormSignup
                usermail={usermailParam}
                hasAccount={true}
                txt={txt}
                country={country}
                handleUpdateCountry={handleUpdateCountry}
                language={language}
                />
        </LayoutOB>
    )
}